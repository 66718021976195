import { useTranslation } from "react-i18next";

export const TextDisplay = () => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                width: "60%",
                margin: "0 auto",
                marginBottom: "50px",
                padding: "40px 0px",
            }}
        >
            <h4
                style={{
                    color: "#262626",
                    fontSize: "35px",
                    marginTop: "50px",
                    textAlign: "center",
                }}
                dangerouslySetInnerHTML={{ __html: t("TextDisplay.description") }}
            >
            </h4>
        </div>
    );
};