import React from "react";

const FloatingWhatsapp = () => {
  const phone = "555432132025"; // Substitua com seu número (sem "+" e com DDD)
  const message = "Olá! Vim através do site e gostaria de saber mais sobre os produtos.";
  const whatsappLink = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;

  const styles = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 9999,
    backgroundColor: "#25D366",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      style={styles}
      aria-label="Fale conosco pelo WhatsApp"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 32 32"
        fill="white"
      >
        <path d="M16.01 2C8.28 2 2 8.24 2 16c0 2.84.74 5.5 2.02 7.84L2 30l6.28-1.99A14.03 14.03 0 0016 30c7.73 0 14-6.26 14-14S23.74 2 16.01 2zm0 25.66c-2.2 0-4.3-.58-6.12-1.6l-.44-.26-3.73 1.18 1.21-3.64-.29-.47A11.55 11.55 0 014.43 16c0-6.4 5.2-11.6 11.6-11.6s11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6zm6.44-8.62c-.35-.17-2.06-1.02-2.38-1.13-.32-.12-.55-.17-.77.17s-.89 1.13-1.1 1.36c-.2.23-.4.25-.74.08-.35-.17-1.48-.55-2.82-1.75-1.04-.92-1.74-2.06-1.95-2.4-.2-.35-.02-.54.15-.71.16-.16.35-.4.52-.6.17-.2.23-.35.35-.58.12-.23.06-.43-.03-.6-.08-.17-.77-1.86-1.05-2.54-.28-.67-.56-.58-.77-.6h-.66c-.23 0-.6.09-.91.43-.32.35-1.2 1.18-1.2 2.87s1.23 3.33 1.4 3.56c.17.23 2.42 3.7 5.88 5.19.82.36 1.46.58 1.96.74.82.26 1.57.22 2.17.13.66-.1 2.06-.84 2.36-1.64.3-.8.3-1.48.21-1.64-.08-.17-.31-.25-.66-.43z" />
      </svg>
    </a>
  );
};

export default FloatingWhatsapp;
