import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../i18n";

const Banner = ({ index }) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const banner = t("Banners", { returnObjects: true })[index];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="col-md-6 col-sm-12 intro-text">
        <h2>
          {banner.title}
          <span style={{ color: "#5BA960" }}>
            {banner.subtitle}
          </span>
        </h2>
      </div>
      <div className="col-md-4 text-right">
        <img
          alt="Banner"
          src={`/img/banners/h${index}.png`}
          style={{
            maxHeight: "500px",
            borderRadius: "10px",
            marginTop: "30%",
            display: isMobile ? "none" : "block", // Oculta no mobile
          }}
        />
      </div>
    </>
  );
};

export default Banner;