import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FooterContainer } from "./footer-container";

export const Catalog = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const sections = t("Catalog.sections", { returnObjects: true });

  // Refs dinâmicos
  const sectionsRef = useMemo(() => {
    const refs = {};
    sections.forEach((section) => {
      refs[section.key] = React.createRef();
    });
    return refs;
  }, [sections]);

  // Scroll para hash
  useEffect(() => {
    const scrollToSection = () => {
      const hash = location.hash.replace("#", "");
      const ref = sectionsRef[hash];
      if (ref && ref.current) {
        const offset = 100;
        const topPosition = ref.current.offsetTop - offset;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      } else {
        // Tenta novamente depois de 100ms, até encontrar o elemento
        setTimeout(scrollToSection, 100);
      }
    };
  
    if (location.hash) {
      scrollToSection();
    }
  }, [location, sectionsRef]);
  
  // Responsividade
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* Header */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "180px",
          color: "white",
        }}
      >
        <h1>{t("Catalog.title")}</h1>
        <h4 style={{ color: "white", fontSize: "13px" }}>{t("Catalog.breadcrumb")}</h4>

        {/* Ícones sociais */}
        <div className="contact-item">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "13px",
              paddingTop: "10px",
              marginBottom: "40px",
            }}
          >
            <a href="https://www.facebook.com/elriplast" target="_blank" rel="noreferrer" style={iconStyle}>
              <FaFacebookF color="white" />
            </a>
            <a href="https://www.instagram.com/elri.plast" target="_blank" rel="noreferrer" style={iconStyle}>
              <FaInstagram color="white" />
            </a>
            <a href="https://www.linkedin.com/company/elriplast" target="_blank" rel="noreferrer" style={iconStyle}>
              <FaLinkedin color="white" />
            </a>
          </div>
        </div>
      </div>

      {/* Seções de Produtos */}
      <div style={{ padding: "50px 0", backgroundColor: "#000", color: "#fff" }}>
        <div style={{ width: "90%", margin: "0 auto" }}>
          {sections.map((section, index) => {
            const contentContainer = {
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: "40px",
            };

            return (
              <section key={index} ref={sectionsRef[section.key]} style={sectionStyle}>
                <div style={contentContainer}>
                  <div style={textContainer}>
                    <h2>{section.title}</h2>

                    {section.description.map((desc, idx) => {
                      if (typeof desc === "string") {
                        const splitChar = desc.includes(":") ? ":" : desc.includes("?") ? "?" : null;
                        if (!splitChar) return <p key={idx}>{desc}</p>;
                        const [head, rest] = desc.split(splitChar);
                        return (
                          <p key={idx}>
                            <strong style={subtitleStyle}>
                              {head}{splitChar}
                            </strong>{" "}
                            {rest}
                          </p>
                        );
                      }

                      if (desc.type === "list") {
                        return (
                          <div key={idx}>
                            <h3 style={{ color: "#4CAF50", marginBottom: "10px" }}>{desc.title}</h3>
                            <ul className="benefits-list">
                              {desc.items.map((item, i) => {
                                const [label, val] = item.includes(":") ? item.split(":") : [null, item];
                                return label ? (
                                  <li key={i}>
                                    <span style={{ color: "#4CAF50", fontWeight: "bold" }}>{label}:</span> {val}
                                  </li>
                                ) : (
                                  <li key={i}>{item}</li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      }

                      return null;
                    })}
                  </div>

                  <div style={imageContainer}>
                    <img src={section.img} alt={section.title} style={imageStyle} />
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </div>

      <FooterContainer />
    </>
  );
};

// === Estilos Globais ===
const iconStyle = {
  border: "1px solid #bcbcbc54",
  borderRadius: "10%",
  padding: "7px",
};

const sectionStyle = {
  backgroundColor: "#1a1a1a",
  borderRadius: "10px",
  padding: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.8)",
  marginBottom: "20px",
  maxWidth: "100%",
};

const textContainer = {
  flex: "1 1 100%",           // força ele a respeitar a largura total
  maxWidth: "100%",           // impede overflow no mobile
  overflowWrap: "break-word", // quebra palavras grandes
  wordBreak: "break-word",
  boxSizing: "border-box",
};

const imageContainer = {
  flex: 1,
  textAlign: "center",
};

const imageStyle = {
  width: "100%",
  maxWidth: "700px",
  height: "auto",
  borderRadius: "10px",
  border: "2px solid #4CAF50",
};

const subtitleStyle = {
  fontSize: "20px",
  color: "#4CAF50",
};

export default Catalog;
