import { ContactLinks } from "./contact-links";
import { GetInTouch } from "./get-in-touch";
import { Banners } from "./banners";

export const Header = () => {
  return (
    <header id="header">
      <div className="container">
        <div className="intro">
          <div className="overlay">
            <div className="row" style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Banners />
            </div>
            <div>
              <GetInTouch />
              <ContactLinks />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
