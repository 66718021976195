import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FooterContainer } from "./footer-container";

export const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* Header da página */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "180px",
          color: "white",
        }}
      >
        <h1>{t("ContactPage.title")}</h1>
        <h4
          style={{
            color: "white",
            fontSize: "13px",
          }}
        >
          {t("ContactPage.breadcrumb")}
        </h4>
        <div className="contact-item">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "13px",
              paddingTop: "10px",
              marginBottom: "40px",
            }}
          >
            <a
              href="https://www.facebook.com/elriplast/?locale=pt_BR"
              target="_blank"
              rel="noreferrer"
              style={{
                border: "1px solid #bcbcbc54",
                borderRadius: "10%",
                padding: "7.5px",
              }}
            >
              <FaFacebookF color="white" />
            </a>
            <a
              href="https://www.instagram.com/elri.plast/?locale=zh-hans&img_index=1"
              target="_blank"
              rel="noreferrer"
              style={{
                border: "1px solid #bcbcbc54",
                borderRadius: "10%",
                padding: "7px",
              }}
            >
              <FaInstagram color="white" />
            </a>
            <a
              href="https://www.linkedin.com/company/elriplast/"
              target="_blank"
              rel="noreferrer"
              style={{
                border: "1px solid #bcbcbc54",
                borderRadius: "10%",
                padding: "7px",
              }}
            >
              <FaLinkedin color="white" />
            </a>
          </div>
        </div>
      </div>

      {/* Informações de Contato */}
      <div id="about" style={{ padding: "50px 0", backgroundColor: "#f7f7f7" }}>
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "100px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <div
          style={{
            width: "300px",
            height: "200px",
            backgroundColor: "#333",
            borderRadius: "15px",
            padding: "20px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "calc(50% - 25px)",
              width: "50px",
              height: "50px",
              backgroundColor: "#4a5b4b",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaMapMarkerAlt color="#5BA960" size={20} />
          </div>
          <h3
            style={{
              color: "#fff",
              fontSize: "18px",
              marginTop: "80px",
              fontWeight: "bold",
            }}
          >
            {t("ContactPage.addressTitle")}
          </h3>
          <span
            style={{
              color: "#808080",
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            {t("ContactPage.address")}
          </span>
        </div>

        <div
          style={{
            width: "300px",
            height: "200px",
            backgroundColor: "#333",
            borderRadius: "15px",
            padding: "20px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "calc(50% - 25px)",
              width: "50px",
              height: "50px",
              backgroundColor: "#4a5b4b",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdEmail color="#5BA960" size={20} />
          </div>
          <h3
            style={{
              color: "#fff",
              fontSize: "18px",
              marginTop: "80px",
              top: "30px",
              fontWeight: "bold",
            }}
          >
            {t("ContactPage.emailTitle")}
          </h3>
          <span
            style={{
              color: "#808080",
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            {t("ContactPage.email")}
          </span>
        </div>

        <div
          style={{
            width: "300px",
            height: "200px",
            backgroundColor: "#333",
            borderRadius: "15px",
            padding: "20px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "calc(50% - 25px)",
              width: "50px",
              height: "50px",
              backgroundColor: "#4a5b4b",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaPhone color="#5BA960" size={20} />
          </div>
          <h3
            style={{
              color: "#fff",
              fontSize: "18px",
              marginTop: "80px",
              fontWeight: "bold",
            }}
          >
            {t("ContactPage.phoneTitle")}
          </h3>
          <span
            style={{
              color: "#808080",
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            {t("ContactPage.phone")}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    style={{
      borderRadius: "15px",
      marginBottom: "50px",
      padding: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#000",
    }}
  >
    <div
      style={{
        width: "90%",
        height: "60%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "20px",
        justifyContent: "center",
        alignItems: "center",
        ...(window.innerWidth <= 768
          ? {
              gridTemplateColumns: "1fr", // Responsividade no mobile
            }
          : {}),
      }}
    >
      <div
        className="form"
        style={{
          marginTop: "-60px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            color: "#5BA960",
            fontSize: "0.8em",
            letterSpacing: "4px",
            fontWeight: "bolder",
          }}
        >
          {t("ContactPage.expandOpportunities")}
        </span>
        <h4
          style={{
            color: "#000",
            fontSize: "35px",
            textAlign: "start",
            width: "80%",
          }}
        >
          {t("ContactPage.contactUsTitle")}
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {t("ContactPage.contactDescription")}
        </div>
      </div>
      <div
        className="formContent"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <form
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20px",
            ...(window.innerWidth <= 768
              ? {
                  gridTemplateColumns: "1fr", // Responsividade no mobile
                }
              : {}),
          }}
        >
          <input
            type="text"
            placeholder={t("ContactForm.namePlaceholder")}
            style={{
              gridColumn: "1 / 2",
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #989898",
              backgroundColor: "transparent",
            }}
          />
          <input
            type="text"
            placeholder={t("ContactForm.emailPlaceholder")}
            style={{
              gridColumn: "2 / 3",
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #989898",
              backgroundColor: "transparent",
            }}
          />
          <input
            type="text"
            placeholder={t("ContactForm.phonePlaceholder")}
            style={{
              gridColumn: "1 / 2",
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #989898",
              backgroundColor: "transparent",
              color: "#fff",
            }}
          />
          <input
            type="text"
            placeholder={t("ContactForm.subjectPlaceholder")}
            style={{
              gridColumn: "2 / 3",
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #989898",
              backgroundColor: "transparent",
            }}
          />
          <textarea
            placeholder={t("ContactForm.messagePlaceholder")}
            style={{
              gridColumn: "1 / 3",
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #989898",
              backgroundColor: "transparent",
              resize: "none",
              height: "100px",
            }}
          />
          <button
            style={{
              gridColumn: "1 / 3",
              padding: "10px",
              borderRadius: "10px",
              border: "none",
              backgroundColor: "#5BA960",
              color: "#fff",
              fontSize: "15px",
              fontWeight: "bolder",
              letterSpacing: "2px",
            }}
          >
            {t("ContactForm.submitButton")}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

      <FooterContainer />
    </div>
  );
};