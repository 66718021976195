import { TextDisplay } from "./text-display";
import { ContactForm } from "./contact-form";
import { ProductDisplay } from "./product-display";

export const Features = () => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <ProductDisplay />          

        <TextDisplay />

        <ContactForm />
      </div>
    </div>
  );
};
