import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const ProductDisplay = () => {
  const { t } = useTranslation();
  const content = t("Features", { returnObjects: true });

  return (
    <>
      <div className="col-md-10 col-md-offset-1 section-title">
        <div style={{ marginTop: "70px" }}>
          <span
            style={{
              color: "#5BA960",
              fontSize: "1em",
              letterSpacing: "4px",
              fontWeight: "bolder",
            }}
          >
            {t("ProductDisplay.labelTitle")}
          </span>
          <h4
            style={{
              color: "#262626",
              fontSize: "40px",
            }}
            dangerouslySetInnerHTML={{
              __html: t("ProductDisplay.labelSubtitle"),
            }}
          ></h4>
        </div>
      </div>

      <div className="row">
        {!!content &&
          content.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-xs-12 col-md-3">
              <div className="box-product">
                <div>
                  <img
                    src={d.img}
                    alt="icon"
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "20px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#5c5c5c",
                      width: "65%",
                      height: "2px",
                      position: "absolute",
                      borderRadius: "20px",
                    }}
                  ></div>
                </div>

                <h3
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textAlign: "center",
                    height: "40px",
                    lineHeight: "1.2",
                    marginTop: "15px",
                  }}
                >
                  {d.title}
                </h3>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() =>
                      d.anchor && (window.location.href = `/catalog#${d.anchor}`)
                    }
                    style={{
                      color: "#fff",
                      backgroundColor: "transparent",
                      border: "1px solid #5BA960",
                      padding: "12px 34px",
                      letterSpacing: "2px",
                      margin: 0,
                      fontSize: "13px",
                      fontWeight: "bolder",
                      borderRadius: "10px",
                      transition: "all 0.5s linear",
                      alignItems: "center",
                    }}
                  >
                    {t("ProductDisplay.learnMore")}
                    <FaArrowRight
                      style={{
                        marginLeft: "10px",
                        paddingTop: "3px",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
