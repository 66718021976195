import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";

export const ContactLinks = (props) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "13px",
        paddingTop: "10px",
      }}
    >
      <a
        href="https://www.facebook.com/elriplast/?locale=pt_BR"
        target="_blank"
        rel="noreferrer"
        style={{
          border: "1px solid #bcbcbc54",
          borderRadius: "10%",
          padding: "7.5px",
        }}
      >
        <FaFacebookF color="white" />
      </a>
      <a
        href="https://www.instagram.com/elri.plast/?locale=zh-hans&img_index=1"
        target="_blank"
        rel="noreferrer"
        style={{
          border: "1px solid #bcbcbc54",
          borderRadius: "10%",
          padding: "7px",
        }}
      >
        <FaInstagram color="white" />
      </a>
      <a
        href="https://www.linkedin.com/company/elriplast/"
        target="_blank"
        rel="noreferrer"
        style={{
          border: "1px solid #bcbcbc54",
          borderRadius: "10%",
          padding: "7px",
        }}
      >
        <FaLinkedin color="white" />
      </a>
    </div>
  )
}