import { useEffect, useState } from 'react';
import Banner from './banner';

export const Banners = () => {
    const [activeBanner, setActiveBanner] = useState(0);

    useEffect(() => {
        const totalBanners = 4;
        const interval = setInterval(() => {
            if (activeBanner === totalBanners - 1) {
                setActiveBanner(0);
                return;
            }
            setActiveBanner(activeBanner + 1);
        }, 5000);

        return () => clearInterval(interval);
    }, [activeBanner]);

    return (
        <>
            <Banner index={activeBanner} />
        </>
    )
}