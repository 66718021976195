import { useTranslation } from "react-i18next";
import "../i18n";

export const Footer = () => {
    const { t } = useTranslation();
    const year = new Date().getFullYear();

    return (
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; {year} {t("Contact.footerPrefix")}
                    <a href="https://www.easytechs.com.br" rel="nofollow">
                        {" "}
                        Easy Techs
                    </a>
                </p>
            </div>
        </div>
    )
}