import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import "../styles/contact-form.css";

export const ContactForm = () => {
    const { t } = useTranslation();

    return (
        <div className="content-form">
            <div className="form-container">
                <div className="form-info">
                    <h4 className="form-title">{t("ContactForm.title")}</h4>
                    <div className="form-details">
                        <div className="form-detail-item">
                            <MdEmail color="#5BA960" />
                            <span>{t("ContactForm.email")}</span>
                        </div>
                        <div className="form-detail-item">
                            <FaMapMarkerAlt color="#5BA960" />
                            <span>{t("ContactForm.address")}</span>
                        </div>
                        <div className="form-detail-item">
                            <FaPhone color="#5BA960" />
                            <span>{t("ContactForm.phone")}</span>
                        </div>
                    </div>
                </div>
                <div className="form-content">
                    <form className="form-build">
                        <input
                            type="text"
                            placeholder={t("ContactForm.namePlaceholder")}
                        />
                        <input
                            type="text"
                            placeholder={t("ContactForm.emailPlaceholder")}
                        />
                        <input
                            type="text"
                            placeholder={t("ContactForm.phonePlaceholder")}
                        />
                        <input
                            type="text"
                            placeholder={t("ContactForm.subjectPlaceholder")}
                        />
                        <textarea
                            placeholder={t("ContactForm.messagePlaceholder")}
                        />
                        <button>{t("ContactForm.submitButton")}</button>
                    </form>
                </div>
            </div>
        </div>
    );
};