import { Footer } from "./footer";
import { FooterLinks } from "./footer-links";

export const FooterContainer = () => {

  return (
    <>
      <FooterLinks />
      <Footer />
    </>
  );
};