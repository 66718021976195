import { useTranslation } from "react-i18next";

export const GetInTouch = () => {
    const { t } = useTranslation();

    return (
        <a href="#features" className="btn btn-custom btn-lg page-scroll">
            {t("GetInTouch")}
            <img
                src="img/arrow.png"
                alt="whatsapp"
                style={{
                    marginLeft: "10px",
                    width: "20px",
                    height: "20px",
                    marginBottom: "3px",
                }}
            />
        </a>
    )
}