import { useTranslation } from "react-i18next";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";

export const FooterLinks = () => {
  const { t } = useTranslation();

    return (
        <div id="contact">
        <div className="container">
          <div className="col-md-3 contact-info">
            <div className="contact-item">
              <img src="img/logo.png" alt="logo" />
            </div>
            <div className="contact-item">
              <div
                style={{
                  display: "flex",
                  gap: "13px",
                  paddingTop: "40px",
                }}
              >
                <a
                  href="https://www.facebook.com/elriplast/?locale=pt_BR"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    border: "1px solid #bcbcbc54",
                    borderRadius: "10%",
                    padding: "7.5px",
                  }}
                >
                  <FaFacebookF color="white" />
                </a>
                <a
                  href="https://www.instagram.com/elri.plast/?locale=zh-hans&img_index=1"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    border: "1px solid #bcbcbc54",
                    borderRadius: "10%",
                    padding: "7px",
                  }}
                >
                  <FaInstagram color="white" />
                </a>
                <a
                  href="https://www.linkedin.com/company/elriplast/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    border: "1px solid #bcbcbc54",
                    borderRadius: "10%",
                    padding: "7px",
                  }}
                >
                  <FaLinkedin color="white" />
                </a>
              </div>
            </div>
          </div>

          {/* Links do Menu */}
          <div className="col-md-3 contact-info">
            <div className="contact-item">
              <h3>{t("Contact.linksTitle")}</h3>
              <p>
                <span>{t("Contact.aboutUs")}</span>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>{t("Contact.products")}</span>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>{t("Contact.contact")}</span>
              </p>
            </div>
            <div className="contact-item">
              <img
                style={{
                  width: "200px",
                  marginLeft: "-35px",
                }}
                src="img/iso.png"
                alt={t("Contact.isoCertification")}
              />
            </div>
          </div>

          {/* Informações de Contato */}
          <div className="col-md-3 contact-info">
            <div className="contact-item">
              <h3>{t("Contact.contactTitle")}</h3>
              <p>
                <span>
                  <i
                    className="fa fa-map-marker"
                    style={{
                      color: "#4CAF50",
                    }}
                  ></i>
                </span>
                {t("ContactForm.address")}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i
                    className="fa fa-phone"
                    style={{
                      color: "#4CAF50",
                    }}
                  ></i>
                </span>
                (54) 3213 - 2025
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i
                    className="fa fa-envelope-o"
                    style={{
                      color: "#4CAF50",
                    }}
                  ></i>
                </span>
                comercial@elri.com.br
              </p>
            </div>
          </div>

          {/* Formulário de Newsletter */}
          <div className="col-md-3 contact-info">
            <div className="contact-item">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  padding: "20px",
                  borderRadius: "15px",
                  border: "1px solid #9F9F9F",
                  width: "300px",
                  height: "200px",
                }}
              >
                <h2
                  style={{
                    color: "#fff",
                    marginBottom: "20px",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "normal",
                    fontSize: "24px",
                  }}
                >
                  {t("Contact.newsletterTitle")}
                </h2>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ccc",
                    borderRadius: "50px",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <input
                    type="email"
                    placeholder={t("Contact.newsletterPlaceholder")}
                    style={{
                      flex: 1,
                      padding: "10px",
                      border: "none",
                      outline: "none",
                      borderRadius: "50px 0 0 50px",
                      backgroundColor: "transparent",
                      color: "#fff",
                      fontFamily: "Arial, sans-serif",
                    }}
                  />

                  <button
                    style={{
                      backgroundColor: "#4CAF50",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderRadius: "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "3px",
                    }}
                  >
                    <FaArrowRight color="white" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}