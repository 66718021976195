import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { FooterContainer } from "./components/footer-container";
import { Catalog } from "./components/catalog";
import { ContactPage } from "./components/contactPage";
import FloatingWhatsapp from "./components/FloatingWhatsapp"; // <- nome atualizado
import "./App.css";

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Header />
              <Features />
              <FooterContainer />
            </div>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <FloatingWhatsapp /> {/* ✅ Botão flutuante sempre visível */}
    </Router>
  );
};

export default App;
