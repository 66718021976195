import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FooterContainer } from "./footer-container";
import "../styles/about.css";

export const About = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          paddingTop: "180px",
          color: "white",
        }}
      >
        <h1>{t("About.title")}</h1>
        <h4
          style={{
            color: "white",
            fontSize: "13px",
          }}
        >
          {t("About.breadcrumb")}
        </h4>
      </div>
      <div className="contact-item">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "13px",
            paddingTop: "10px",
            marginBottom: "40px",
          }}
        >
          <a
            href="https://www.facebook.com/elriplast/?locale=pt_BR"
            target="_blank"
            rel="noreferrer"
            style={{
              border: "1px solid #bcbcbc54",
              borderRadius: "10%",
              padding: "7.5px",
            }}
          >
            <FaFacebookF color="white" />
          </a>
          <a
            href="https://www.instagram.com/elri.plast/?locale=zh-hans&img_index=1"
            target="_blank"
            rel="noreferrer"
            style={{
              border: "1px solid #bcbcbc54",
              borderRadius: "10%",
              padding: "7px",
            }}
          >
            <FaInstagram color="white" />
          </a>
          <a
            href="https://www.linkedin.com/company/elriplast/"
            target="_blank"
            rel="noreferrer"
            style={{
              border: "1px solid #bcbcbc54",
              borderRadius: "10%",
              padding: "7px",
            }}
          >
            <FaLinkedin color="white" />
          </a>
        </div>
      </div>

      <div id="about" style={{ padding: "50px 0", backgroundColor: "white" }}>
        <div className="container">
          <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "40px" }}>
            <img
              src="/img/elri.png" // <- substitua pelo nome real da sua imagem
              alt="Sobre a Elri"
              style={{
                maxWidth: "100%",
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
          </div>

          <div className="row" style={{ marginTop: "30px", display: "flex", flexWrap: "wrap" }}>
            {t("About.sections", { returnObjects: true }).map((section, index) => (
              <div
                className="col-md-6"
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  flex: "0 0 48%",
                  marginRight: "2%",
                  boxSizing: "border-box",
                }}
              >
                <h4
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "700",
                  }}
                >
                  <span
                    style={{
                      color: "#5BA960",
                      fontSize: "1em",
                      fontWeight: "700",
                    }}
                  >
                    {section.number}{" "}
                  </span>
                  {section.title}
                </h4>
                <p>{section.description}</p>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <span
              style={{
                color: "#5BA960",
                fontSize: "0.8em",
                letterSpacing: "4px",
                fontWeight: "bolder",
              }}
            >
              {t("About.guidelineLabel")}
            </span>
            <p
              style={{
                color: "#000",
                fontSize: "29px",
                width: "38%",
                textAlign: "center",
                lineHeight: "1.2",
                fontWeight: "bold",
              }}
            >
              {t("About.guidelineTitle")}
            </p>
          </div>

          <div className="row" style={{ marginTop: "50px", textAlign: "center" }}>
            {t("About.values", { returnObjects: true }).map((value, index) => (
              <div className="col-md-4" key={index}>
                <div
                  style={{
                    padding: "30px",
                    backgroundColor: "#1c1c1c",
                    color: "white",
                    borderRadius: "8px",
                    height: "340px",
                  }}
                >
                  <h4 style={{ color: "white", fontSize: "1.5em" }}>{value.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: value.description}}></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FooterContainer />
    </>
  );
};