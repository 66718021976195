import React, { useContext } from "react";
import "../styles/LanguageSwitcher.css";
import { LanguageContext } from "../LanguageContext";

export const LanguageSwitcher = () => {
  const { changeLanguage } = useContext(LanguageContext);

  return (
    <div className="language-switcher">
      <button onClick={() => changeLanguage("en")} className="lang-button">
        <img src="/img/usa.svg" width={'20px'} alt="English" />
      </button>
      <button onClick={() => changeLanguage("pt")} className="lang-button">
        <img src="/img/brazil.svg" width={'20px'} alt="Português" />
      </button>
      <button onClick={() => changeLanguage("es")} className="lang-button">
        <img src="/img/spain.svg" width={'20px'} alt="Español" />
      </button>
    </div>
  );
};