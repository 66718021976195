import { Link } from "react-router-dom";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

export const Navigation = () => {
  const { t } = useTranslation();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    navList: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: isMobile ? "10px" : "20px",
      alignItems: isMobile ? "flex-start" : "center",
      flexWrap: "wrap",
    },
    submenuWrapper: {
      position: "relative",
      cursor: "pointer",
    },
    submenu: {
      position: isMobile ? "relative" : "absolute",
      top: isMobile ? "auto" : "100%",
      left: 0,
      backgroundColor: "#4CAF50",
      listStyle: "none",
      padding: isMobile ? "0" : "15px 0",
      margin: 0,
      borderRadius: isMobile ? "0" : "5px",
      minWidth: isMobile ? "100%" : "200px",
      boxShadow: isMobile ? "none" : "0 4px 8px rgba(0, 0, 0, 0.8)",
      zIndex: 999,
    },
    submenuItem: {
      display: "block",
      padding: "8px 15px",
      paddingLeft: isMobile ? "30px" : "15px",
      color: "#fff",
      textDecoration: "none",
      transition: "background 0.3s ease",
    },
    hoverButton: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    languageSwitcher: {
      marginLeft: isMobile ? "0" : "auto",
    },
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
            id="toggle-button"
          >
            <span className="sr-only">{t("Navigation.toggleNavigation")}</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a
            className="navbar-brand page-scroll"
            style={{ padding: "0 0 0 15px" }}
            href="/"
          >
            <img src="img/logo.png" alt="logo" />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
          style={{
            overflow: showSubMenu && isMobile ? "visible" : "hidden",
            minHeight: showSubMenu && isMobile ? "550px" : undefined,
            transition: "all 0.3s ease",
          }}
        >
          <ul className="nav navbar-nav navbar-right" style={styles.navList}>
            <li>
              <a href="/" className="page-scroll">
                {t("Navigation.home")}
              </a>
            </li>
            <li>
              <Link to="/about">{t("Navigation.aboutUs")}</Link>
            </li>

            <li
              style={styles.submenuWrapper}
              onMouseEnter={() => !isMobile && setShowSubMenu(true)}
              onMouseLeave={() => !isMobile && setShowSubMenu(false)}
            >
              <a
                href="#"
                className="page-scroll"
                onClick={(e) => {
                  e.preventDefault();
                  if (isMobile) setShowSubMenu((prev) => !prev);
                }}
                style={{
                  display: "inline-block",
                  lineHeight: "20px",
                  verticalAlign: "middle",
                  color: "#fff",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("Navigation.products")}
              </a>


              {showSubMenu && (
                <ul style={styles.submenu}>
                  <li>
                    <a href="/catalog" style={styles.submenuItem}>
                      {t("Navigation.submenu.allProducts") || "Todos os Produtos"}
                    </a>
                  </li>
                  <li>
                    <a href="/catalog#tapetesAutomotivos" style={styles.submenuItem}>
                      {t("Navigation.submenu.tapetesAutomotivos")}
                    </a>
                  </li>
                  <li>
                    <a href="/catalog#tapetesLinhaPesada" style={styles.submenuItem}>
                      {t("Navigation.submenu.tapetesLinhaPesada")}
                    </a>
                  </li>
                  <li>
                    <a href="/catalog#linhaIndustrial" style={styles.submenuItem}>
                      {t("Navigation.submenu.linhaIndustrial")}
                    </a>
                  </li>
                  <li>
                    <a href="/catalog#linhaEmbalagens" style={styles.submenuItem}>
                      {t("Navigation.submenu.linhaEmbalagens")}
                    </a>
                  </li>
                  <li>
                    <a href="/catalog#linhaLogistica" style={styles.submenuItem}>
                      {t("Navigation.submenu.linhaLogistica")}
                    </a>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <a href="/contact" className="page-scroll">
                {t("Navigation.contact")}
              </a>
            </li>
            <li>
              <div style={styles.hoverButton}>
                {t("Navigation.getInTouch")}
                <img
                  src="img/arrow-grey.png"
                  alt={t("Navigation.whatsapp")}
                  style={{ marginLeft: "10px", width: "20px", height: "20px" }}
                />
              </div>
            </li>
            <li style={styles.languageSwitcher}>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
